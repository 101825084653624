import React from 'react';

import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import useStyles from './footer.styles';
import {
  FACEBOOK_LINK, LINKEDIN_LINK, TWITTER_LINK, INSTAGRAM_LINK,
} from './footer.constants';


function Footer() {
  const styles = useStyles();
  return (
    <div className={styles.div__root}>
      <div className={styles.div__versao}>
        <span>
          Versão 1.0.0
        </span>
      </div>
      <div className={styles.div__icones}>
        <div>
          <a className={styles.colorLink} href={INSTAGRAM_LINK} rel="noopener noreferrer" target="_blank">
            <InstagramIcon />
          </a>
        </div>
        <div>
          <a className={styles.colorLink} href={LINKEDIN_LINK} rel="noopener noreferrer" target="_blank">
            <LinkedInIcon />
          </a>
        </div>
        <div>
          <a className={styles.colorLink} href={FACEBOOK_LINK} rel="noopener noreferrer" target="_blank">
            <FacebookIcon />
          </a>
        </div>
        <div>
          <a className={styles.colorLink} href={TWITTER_LINK} rel="noopener noreferrer" target="_blank">
            <TwitterIcon />
          </a>
        </div>
      </div>
    </div>

  );
}

export default Footer;
