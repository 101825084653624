import makeStyles from '@material-ui/core/styles/makeStyles';
import { WARM_GRAY } from '../../utils/colors/colors.contants';

export default makeStyles({
  headerContainer: {
    padding: '1.5% 3.9%',
  },
  headerVerticalLine: {
    borderRight: `1px solid ${WARM_GRAY}`,
    height: '52px',
    '@media screen and (max-width: 600px)': {
      height: '32px',
    },
  },
  headerLogoDourado: {
    width: 'auto',
    maxWidth: '145px',
    height: 'auto',
    '@media screen and (max-width: 666px)': {
      maxWidth: '80px',
    },
  },
  headerLogoCadeado: {
    width: 'auto',
    maxWidth: '119px',
    height: 'auto',
    '@media screen and (max-width: 666px)': {
      maxWidth: '90px',
    },
  },
  colorLink: {
    color: 'inherit',
  },
  divLinks: {
    paddingLeft: '16px',
  },
  grid__pointer: {
    cursor: 'pointer',
  },
});
