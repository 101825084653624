import { call, put, takeLatest } from 'redux-saga/effects';
import { START_AUTHENTICATION } from './autenticacao.constants';
import { getAuthentication } from '../../services/Autenticacao/autenticacao.service';
import {
  setAuthenticateAction,
  setAuthenticationFailureAction,
  setAuthenticationValuesAction,
  setLodingAction,
} from './autenticacao.store';
import {
  clearSession,
  getErrorMessage,
  isInformacoesNaoEncontradas,
  isPrazoExpiradoMessage,
  replaceTo,
  saveAuthDataInSession,
} from '../../utils/utils';

export const startAuthenticationAction = (credentials, history) => ({
  type: START_AUTHENTICATION,
  credentials,
  history,
});

function* authenticationSucces(data, history) {
  yield put(setAuthenticateAction(data));
  yield call(saveAuthDataInSession, data);
  yield replaceTo(history, '/');
}

function* authenticationFailure(exception, history) {
  const error = yield getErrorMessage(exception);
  if (isInformacoesNaoEncontradas(error)) {
    yield put(setAuthenticationFailureAction(error, true));
    yield put(setAuthenticationValuesAction('isPrazoExpirado', false));
  } else if (isPrazoExpiradoMessage(error)) {
    yield put(setAuthenticationFailureAction(error, false));
    yield put(setAuthenticationValuesAction('isPrazoExpirado', true));
  }
  yield clearSession();
  yield put(setAuthenticateAction(null));
  yield replaceTo(history, '/acesso-negado');
}

function* attemptAuthenticate(credentials, history) {
  try {
    const { data } = yield call(getAuthentication, credentials);
    yield authenticationSucces(data, history);
  } catch (exception) {
    yield authenticationFailure(exception, history);
  }
}

function* statAuthenticationHandler(action) {
  const { credentials, history } = action;
  yield put(setLodingAction(true));
  yield attemptAuthenticate(credentials, history);
}

export default function* watchAuthentication() {
  yield takeLatest(START_AUTHENTICATION, statAuthenticationHandler);
}
