import React, { useCallback } from 'react';
import Grid from '@material-ui/core/Grid';

import { useHistory } from 'react-router';
import logoDourado from '../../assets/Logos/logo-abe.png';
import logoCadeado from '../../assets/Logos/logo-acordo-seguro-cadeado.png';
import useStyles from './header.styles';
import HeaderContainer from '../UI/Background/HeaderConainer';


function Header() {
  const styles = useStyles();
  const history = useHistory();

  const onClickLogoAbe = useCallback(() => {
    if (window.location.pathname === '/') {
      window.location.reload();
    } else {
      history.replace('/');
    }
  }, [history]);

  return (
    <HeaderContainer>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        className={styles.headerContainer}
      >
        <Grid
          direction="row"
          alignItems="center"
          justify="flex-start"
          container
          item
          lg={6}
          md={6}
          sm={8}
          xs={8}
          spacing={2}
        >
          <Grid item className={styles.grid__pointer} onClick={onClickLogoAbe}>
            <img
              className={styles.headerLogoDourado}
              src={logoDourado}
              alt="Abe"
            />
          </Grid>
          <Grid item className={styles.grid__pointer} onClick={onClickLogoAbe}>
            <div className={styles.headerVerticalLine} />
          </Grid>
          <Grid item className={styles.grid__pointer} onClick={onClickLogoAbe}>
            <img
              className={styles.headerLogoCadeado}
              src={logoCadeado}
              alt="Acordo Seguro"
            />
          </Grid>
        </Grid>
      </Grid>
    </HeaderContainer>
  );
}

export default Header;
