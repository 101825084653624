import makeStyles from '@material-ui/core/styles/makeStyles';
import { BLACK_TWO, PURE_GRAY } from '../../utils/colors/colors.contants';


export default makeStyles({

  div__root: {
    minHeight: '70px',
    maxHeight: '100px',
    backgroundColor: PURE_GRAY,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    '@media screen and (max-width: 600px)': {
      display: 'block',
    },
  },
  div__versao: {
    width: '50%',
    display: 'flex',
    marginLeft: '46px',
    fontSize: '12px',
    color: BLACK_TWO,
    '@media screen and (max-width: 600px)': {
      width: '100%',
      marginLeft: 0,
      justifyContent: 'center',
      '& span': {
        marginTop: '14px',
        marginBottom: '18px',
      },
    },
  },


  div__icones: {
    width: '50%',
    display: 'flex',
    justifyContent: 'flex-end',
    '& div': {
      marginRight: '16px',
    },
    '& div:last-child': {
      marginRight: '45px',
    },
    '@media screen and (max-width: 600px)': {
      justifyContent: 'center',
      width: '100%',
      '& div:first-child': {
        marginLeft: '47px',
      },
    },
  },

  colorLink: {
    color: 'inherit',
    '@media screen and (max-width: 600px)': {
        '& .MuiSvgIcon-root': {
            fontSize: '30px',
        },
    },
  },
});
