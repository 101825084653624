import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';
import UILoading from '../UI/Loading/UILoading';
import Header from '../Header/Header';
import ButtonUI from '../UI/Button/ButtonUI';
import useStyles from './Negociar.styles';
import FadeUI from '../UI/Transitions/FadeUI';
import imgCodigoBararras from '../../assets/Buttons/codigo-de-barras-branco.png';
import imgCartaoCredito from '../../assets/Buttons/cartao-de-credito-branco.png';
import imgPix from '../../assets/Buttons/pix-branco.png';
import ImagemCardHeader from '../../models/ImagemCardHeader';
import CardHeader from '../UI/Card/CardHeader';
import ListButtonOptions from '../UI/Button/ListButton';
import { selectOpcaoPagamentoAction, setDialogPageViewAction } from '../../store/negociar/negociar.store';
import {
  decoderFormaPagamento,
  formatMoney, getImageSessionStorageIfExists, getItemFromSession, replaceTo,
} from '../../utils/utils';
import PaperUI from '../UI/Paper/PaperUI';
import { getOpcoesAcordoAction, realizarAcordoDividaAction } from '../../store/negociar/negociar.saga';
import { resetErrors, schema } from '../CreditCard/cartaoCreditoValidator';
import CartaoCredito from '../CreditCard/CartaoCredito';
import DialogUI from '../UI/DialogUI/DialogUI';
import { useNegociarDispatch, useNegociarSelector } from '../../store/negociar/NegociarProvider';
import BodyContainer from '../UI/Background/BodyContainer';
import { formaPagamentoEnum } from '../../utils/utils.constants';
import {
  getOptionPagamentoMsg,
  OPCOES_PAGAMENTO_SUB_TITLE_DEFAULT,
  OPCOES_PAGAMENTO_TITLE_DEFAULT,
  PAGAMENTO_PIX_SUB_TITLE,
  PAGAMENTO_PIX_TITLE,
} from './negociar.constants';
import PassoUI from '../UI/PassoUI/PassoUI';

const imgCardHeaders = [
  new ImagemCardHeader(imgCodigoBararras, 'BOLETO BANCÁRIO', 'Negociar com boleto bancário', 70, 45),
  new ImagemCardHeader(imgCartaoCredito, 'CARTÃO DE CRÉDITO', 'Negociar com cartão de crédito', 70, 45),
  new ImagemCardHeader(imgPix, 'PIX', 'Negociar com PIX', 70, 45),
];

const errorCartaoInitialState = {
  number: {
    error: false,
    message: '',
  },
  name: {
    error: false,
    message: '',
  },
  expiry: {
    error: false,
    message: '',
  },
  cvc: {
    error: false,
    message: '',
  },
};

// imgQuality é a forma de pagamento.
// idFotoUsuario é o idProcesso.
function Negociar({ formaPagamento = decoderFormaPagamento(getItemFromSession('imgQuality')), idProcesso = getItemFromSession('idFotoUsuario') }) {
  const dispatch = useNegociarDispatch();
  const history = useHistory();
  const styles = useStyles();

  const [focusCartao, setFocusCartao] = useState('');
  const [erros, setErros] = useState(errorCartaoInitialState);
  const [hasErrors, setHasErrors] = useState(false);

  const [finalizarPagamento, setFinalizarPagamento] = useState(false);

  const showLoading = useNegociarSelector(states => states.actionsPageView.loading);
  const dialog = useNegociarSelector(states => states.actionsPageView.dialog);
  const opcaoPagamentoSelecionada = useNegociarSelector(states => states.opcaoPagamentoSelected);
  const opcoesPagamentos = useNegociarSelector(states => states.opcoesPagamento);
  const dadosCartao = useNegociarSelector(states => states.dadosCartaoCredito);

  // Caso a forma de pagamento salva no storage não exista
  // O usuário será redirecionado para a Home.
  useEffect(() => {
    if (!formaPagamento) {
      history.push('/');
    }
  }, [formaPagamento, history]);

  const [logoCliente] = useState(() => {
    const { imagePreviewUrl } = JSON.parse(getImageSessionStorageIfExists());
    return imagePreviewUrl;
  });

  const setFinlizarPagamentoHandler = useCallback(() => {
    setFinalizarPagamento(!finalizarPagamento);
    setErros(resetErrors());
  }, [finalizarPagamento, setErros]);

  const onBackHomePage = useCallback(() => {
    history.replace('/');
  }, [history]);

  const onSelectOption = useCallback((opcaoPagamentoSelected) => {
    dispatch(selectOpcaoPagamentoAction(opcaoPagamentoSelected));
  }, [dispatch]);

  const onCloseDialogHandler = useCallback(() => {
    dispatch(setDialogPageViewAction({ open: false }));
    if (dialog.isSucess) {
      replaceTo(history, '/');
    }
  }, [dispatch, history, dialog]);

  const onFocusCartaoHandler = useCallback((event) => {
    const { name } = event.target;
    setErros({
      ...erros,
      [name]: {
        error: false,
      },
    });
    setFocusCartao(name);
  }, [setFocusCartao, erros, setErros]);

  const onSetErrorInCardValidator = useCallback((name, isError, message) => {
    setErros({
      ...erros,
      [name]: {
        error: isError,
        message,
      },
    });
  }, [setErros, erros]);

  const onBlurCvvHandler = useCallback(() => {
    setFocusCartao('');
  }, [setFocusCartao]);

  const onFinalizarAcordoCartaoCredito = useCallback(async () => {
    setHasErrors(false);
    const schemaToValidate = {
      number: dadosCartao.number,
      name: dadosCartao.name,
      expiry: dadosCartao.validExpiry,
      cvc: dadosCartao.cvc,
    };
    try {
      await schema.validate(schemaToValidate, { abortEarly: false });
      if (!hasErrors) {
        dispatch(realizarAcordoDividaAction(formaPagamento, idProcesso, history, onSetErrorInCardValidator));
      }
    } catch (e) {
      setHasErrors(true);
      e.inner.forEach((err) => {
        erros[err.path].error = true;
        erros[err.path].message = err.message;
      });
      setErros({ ...erros });
    }
  }, [setErros, erros, dadosCartao, dispatch, formaPagamento, idProcesso, history, hasErrors, onSetErrorInCardValidator]);


  const onFinalizarAcordo = useCallback(() => {
    dispatch(realizarAcordoDividaAction(formaPagamento, idProcesso, history));
  }, [dispatch, formaPagamento, idProcesso, history]);

  // Busca as opcoes de pagamento do acordo.
  useEffect(() => {
    dispatch(getOpcoesAcordoAction(formaPagamento, idProcesso));
  }, [dispatch, formaPagamento, idProcesso]);

  const getImageCardHeader = useCallback(() => {
    if (formaPagamento === formaPagamentoEnum.CARTAO_CREDITO) {
      return imgCardHeaders[1];
    }
    if (formaPagamento === formaPagamentoEnum.PIX) {
      return imgCardHeaders[2];
    }
    return imgCardHeaders[0];
  }, [formaPagamento]);

  const valorTotalOpcaoPagamentoSelecionadaCartaoCredito = () => (
    <Grid item lg={12} md={12} sm={12} xs={12}>
      <Grid container item lg={12} md={12} sm={12} xs={12} className={styles.containerOpcaPagamentoSelecionadaCartaoCredito}>
        <Grid
          item
          lg={3}
          md={4}
          sm={4}
          xs={12}
          className={styles.containerOpcaPagamentoSelecionadaCartaoCreditoTitle}
        >
          <Typography component="p">OPÇÃO ESCOLHIDA</Typography>
        </Grid>
        <Grid
          container
          item
          spacing={2}
          lg={9}
          md={8}
          sm={8}
          xs={12}
          justify="center"
          alignItems="center"
        >
          <Grid item className={styles.containerOpcaPagamentoSelecionadaCartaoCreditoSubTitle}>
            <Typography
              component="p"
            >
              {`${opcaoPagamentoSelecionada.qtdeParcelas}x de ${formatMoney(opcaoPagamentoSelecionada.valorParcela)}`}
            </Typography>
          </Grid>
          <Grid item className={styles.containerOpcaPagamentoSelecionadaCartaoCreditoValor}>
            <Typography component="p">{`Total: ${formatMoney(opcaoPagamentoSelecionada.valorTotal)}`}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  const opcoesPagamento = () => (
    <>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <CardHeader img={getImageCardHeader()} />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Grid container spacing={1}>
          <Grid item lg={12} md={12} sm={12} xs={12} className={styles.gridContainerTitle}>
            <PassoUI numero="3" />
            <Typography component="p">
              {getOptionPagamentoMsg(formaPagamento, PAGAMENTO_PIX_TITLE, OPCOES_PAGAMENTO_TITLE_DEFAULT)}
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12} className={styles.gridContainerSubTitle}>
            <Typography component="p">
              {getOptionPagamentoMsg(formaPagamento, PAGAMENTO_PIX_SUB_TITLE, OPCOES_PAGAMENTO_SUB_TITLE_DEFAULT)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <ListButtonOptions
          options={opcoesPagamentos}
          onSelectOption={onSelectOption}
          qtdeParcelasDefault={opcaoPagamentoSelecionada.qtdeParcelas}
        />
      </Grid>
    </>
  );

  const getComponentSelecionarOpcaoPagamento = () => (
    <>
      <Grid item lg={12} md={12} sm={12} xs={12} className={styles.arrowButtonJustifyInMobile}>
        <ButtonUI label="VOLTAR" onClick={onBackHomePage} variant="arrow" />
      </Grid>
      {opcoesPagamento()}
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Grid container spacing={2} className={styles.gridContainerProsseguirContainer}>
          <Grid
            container
            item
            alignItems="center"
            justify="flex-end"
            lg={9}
            md={9}
            sm={12}
            xs={12}
            className={styles.gridContainerProsseguirLabel}
          >
            <Typography component="p">
              {`TOTAL: ${formatMoney(opcaoPagamentoSelecionada.valorTotal)}`}
            </Typography>
          </Grid>
          <Grid
            container
            item
            alignItems="center"
            justify="flex-end"
            lg={3}
            md={3}
            sm={12}
            xs={12}
            className={styles.contaienrJustifyCenterInMobile}
          >
            <ButtonUI onClick={setFinlizarPagamentoHandler} label="PROSSEGUIR" variant="primary" />
          </Grid>
        </Grid>
      </Grid>
    </>
  );

  const getComponentBoleto = () => (
    <>
      <Grid item lg={12} md={12} sm={12} xs={12} className={styles.arrowButtonJustifyInMobile}>
        <ButtonUI label="VOLTAR" onClick={setFinlizarPagamentoHandler} variant="arrow" />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <CardHeader img={imgCardHeaders[0]} />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className={styles.containerImprimirBoletoTitle}>
        <Typography component="p">
          - Imprima o boleto e
          <strong> pague no banco ou pague pela internet </strong>
          utilizando o código de barras do boleto.
        </Typography>
      </Grid>
      <Grid container item lg={12} md={12} sm={12} xs={12}>
        <Grid container item>
          <div className={styles.containerDivider} />
        </Grid>
        <Grid container item>
          <Grid item lg={12} md={12} sm={12} xs={12} className={styles.conatinerDivider} />
          <Grid
            item
            container
            lg={9}
            md={9}
            sm={6}
            xs={5}
            alignItems="center"
            justify="flex-end"
            className={styles.containerValorSerPago}
          >
            <Typography component="p"> VALOR A SER PAGO </Typography>
          </Grid>
          <Grid
            container
            item
            lg={3}
            md={3}
            sm={6}
            xs={7}
            alignItems="center"
            justify="center"
            className={styles.containerValorTotalValor}
          >
            <Typography component="p">
              {`${opcaoPagamentoSelecionada.qtdeParcelas}x de ${formatMoney(opcaoPagamentoSelecionada.valorParcela)}`}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item alignItems="center" justify="flex-end" className={styles.contaienrJustifyCenterInMobile}>
        <PassoUI numero="4" margin="right" />
        <ButtonUI label="GERAR BOLETO" onClick={onFinalizarAcordo} variant="primary" />
      </Grid>
    </>
  );

  const getComponentCartao = () => (
    <>
      <Grid item lg={12} md={12} sm={12} xs={12} className={styles.arrowButtonJustifyInMobile}>
        <ButtonUI label="VOLTAR" onClick={setFinlizarPagamentoHandler} variant="arrow" />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <CardHeader img={imgCardHeaders[1]} />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className={styles.containerDadosCartaoTitle}>
        <Typography component="h3">
          Dados do Cartão
        </Typography>
        <Typography component="p">
          Preencha corretamente todos os campos abaixo com os dados do seu cartão.
        </Typography>
      </Grid>
      {valorTotalOpcaoPagamentoSelecionadaCartaoCredito()}
      <CartaoCredito
        dadosCartao={dadosCartao}
        errosFormCartao={erros}
        focus={focusCartao}
        onBlurCvvHandler={onBlurCvvHandler}
        onFocusHandler={onFocusCartaoHandler}
      />
      <Grid container spacing={1}>
        <Grid container item lg={9}>
          <Grid
            container
            item
            alignItems="center"
            justify="flex-end"
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className={styles.gridContainerPassoUI}
          >
            <PassoUI numero="4" margin="right" />
          </Grid>
        </Grid>
        <Grid container item lg={3}>
          <Grid
            container
            item
            alignItems="center"
            justify="center"
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className={styles.gridContainerPagamentoMobile}
          >
            <Typography
              component="p"
            >
              {`TOTAL: ${formatMoney(opcaoPagamentoSelecionada.valorTotal)}`
              }
            </Typography>
          </Grid>
          <Grid
            container
            item
            alignItems="center"
            justify="center"
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className={styles.gridContainerPagamentoMobile}
          >
            <ButtonUI onClick={onFinalizarAcordoCartaoCredito} label="CONFIRMAR PAGAMENTO" variant="primary" />
          </Grid>
        </Grid>
      </Grid>
    </>
  );


  const getComponentPix = () => (
    <>
      <Grid item lg={12} md={12} sm={12} xs={12} className={styles.arrowButtonJustifyInMobile}>
        <ButtonUI label="VOLTAR" onClick={setFinlizarPagamentoHandler} variant="arrow" />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <CardHeader img={imgCardHeaders[2]} />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className={styles.containerImprimirBoletoTitle}>
        <Typography component="p">
          - Imprima o PIX e pague utilizando o QR Code.
        </Typography>
      </Grid>
      <Grid container item lg={12} md={12} sm={12} xs={12}>
        <Grid container item>
          <div className={styles.containerDivider} />
        </Grid>
        <Grid container item>
          <Grid item lg={12} md={12} sm={12} xs={12} className={styles.conatinerDivider} />
          <Grid
            item
            container
            lg={9}
            md={9}
            sm={6}
            xs={5}
            alignItems="center"
            justify="flex-end"
            className={styles.containerValorSerPago}
          >
            <Typography component="p"> VALOR A SER PAGO </Typography>
          </Grid>
          <Grid
            container
            item
            lg={3}
            md={3}
            sm={6}
            xs={7}
            alignItems="center"
            justify="center"
            className={styles.containerValorTotalValor}
          >
            <Typography component="p">
              {`${opcaoPagamentoSelecionada.qtdeParcelas}x de ${formatMoney(opcaoPagamentoSelecionada.valorParcela)}`}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item alignItems="center" justify="flex-end" className={styles.contaienrJustifyCenterInMobile}>
        <PassoUI numero="4" margin="right" />
        <ButtonUI label="GERAR QR Code" onClick={onFinalizarAcordo} variant="primary" />
      </Grid>
    </>
  );

  const getFinalizarPagamento = () => {
    if (formaPagamento === formaPagamentoEnum.CARTAO_CREDITO) {
      return getComponentCartao();
    }
    if (formaPagamento === formaPagamentoEnum.PIX) {
      return getComponentPix();
    }
    return getComponentBoleto();
  };

  return (
    <>
      <DialogUI
        contentText={dialog.contentText}
        infoContentText={dialog.infoContentText}
        confirmButtonText={dialog.confirmButtonText}
        icon={dialog.icon}
        isSucess={dialog.isSucess}
        open={dialog.open}
        onCloseHandler={() => onCloseDialogHandler()}
      />
      <UILoading show={showLoading} />
      <Header />
      <BodyContainer>
        <FadeUI timeoutEffect={1000}>
          <Paper elevation={0} className={styles.gridContainer}>
            <Grid container spacing={2}>
              {finalizarPagamento ? getFinalizarPagamento() : getComponentSelecionarOpcaoPagamento()}
            </Grid>
          </Paper>
        </FadeUI>
        <Grid container className={styles.container}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <PaperUI>
              <FadeUI timeoutEffect={1000}>
                <Grid
                  container
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  justify="center"
                  alignItems="center"
                  className={styles.containerLogoCliente}
                >
                  {logoCliente && <img width="auto" height="auto" src={logoCliente} alt="" />}
                </Grid>
              </FadeUI>
            </PaperUI>
          </Grid>
        </Grid>
      </BodyContainer>
    </>
  );
}

export default Negociar;
