import React from 'react';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { useSelector } from 'react-redux';
import useStyles from './canaisAtendimento.styles';
import FadeUI from '../../UI/Transitions/FadeUI';
import UILoading from '../../UI/Loading/UILoading';

function CanaisAtendimento({ logoCliente }) {
  const styles = useStyles();
  const loadingLogoCliente = useSelector(states => states.homeStore.actionsPageView.loadingLogoCliente);
  const canaisAtendimento = useSelector(states => states.homeStore.canaisAtendimento);

  return (
    <>
      <UILoading show={loadingLogoCliente} />
      <Grid container item lg={12} className={styles.gridBoxShadow}>
        <Grid container item lg={12} alignItems="center" justify="center" className={styles.gridLogoEmpresa}>
          {logoCliente && (
          <FadeUI loading={loadingLogoCliente} timeoutEffect={1000}>
            <img src={logoCliente} alt="" className={styles.imgLogoCliente} />
          </FadeUI>
          )}
        </Grid>
        <FadeUI loading={loadingLogoCliente} timeoutEffect={1000}>
          <Grid container item lg={12} alignItems="center" justify="center">
            <Typography component="p" className={styles.typograpyTitle}>ATENDIMENTO:</Typography>
          </Grid>
        </FadeUI>
        <Grid container item lg={12} alignItems="center" justify="center">
          <FadeUI loading={loadingLogoCliente} timeoutEffect={1000}>
            <Typography component="p" className={styles.typograpyInfo}>
              {canaisAtendimento.emailContato}
            </Typography>
          </FadeUI>
        </Grid>
        <Grid container item lg={12} alignItems="center" justify="center">
          <FadeUI loading={loadingLogoCliente} timeoutEffect={1000}>
            <Typography component="p" className={styles.typograpyInfo}>
              {canaisAtendimento.telefoneContato1}
            </Typography>
          </FadeUI>
        </Grid>
        <Grid container item lg={12} alignItems="center" justify="center">
          <FadeUI loading={loadingLogoCliente} timeoutEffect={1000}>
            <Typography component="p" className={styles.typograpyInfo}>
              {canaisAtendimento.telefoneContato2}
            </Typography>
          </FadeUI>
        </Grid>
        <Grid container item lg={12} alignItems="center" justify="center">
          <FadeUI loading={loadingLogoCliente} timeoutEffect={1000}>
            <Typography component="p" className={styles.typograpyInfo}>
              {canaisAtendimento.celularContato1}
            </Typography>
          </FadeUI>
        </Grid>
      </Grid>
    </>
  );
}

export default CanaisAtendimento;
